const collections = {
    videos: 'videos',
    events: 'events',
    musicians:  'musicians',
    texts: 'textContent',
    donors: 'donors',
    grants: 'grants',
    // csv: 'CSVs',
    csv: 'CSVs',
    subscribers: 'subscribers',
    images: 'images',
    campaigns: 'campaigns',
    tags: 'tags',
    lists: 'lists'
};

export default collections;
